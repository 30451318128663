import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import LoggedLayout from "layouts/LoggedLayout";
import TrSkeleton from "components/TrSkeleton";
import { getRouterBasename } from 'helpers/config';
import { formatNumber, jsonToArray, openPopupWindow } from 'helpers/generic';
import ReconciliationModal from './ReconciliationModal';
import TradeCategoryModal from './TradeCategoryModal';
import BusinessChecker from 'components/BusinessChecker';
import { default as TreasuriesService } from 'services/Treasuries';
import { default as BankReconciliationService } from 'services/BankReconciliation';

let switchToPreventMultipleRefreshingAtSameTime = false;

const Table = styled.table`

	td {
		padding: 2px 5px;
		font-size: 13px;

		.badge {
			padding: 0.2em 0.45em;
			cursor: pointer;

			&:hover {
				box-shadow: 0px 0px 5px white;
			}
		}

		&.red, .red {
			color: red !important;
			font-weight: bold;
		}

		&.green, .green {
			color: green !important;
			font-weight: bold;
		}

		&.reconciliation-btn-wrapper {
			vertical-align: middle;
			width: 35px;
			min-width: 35px;
			max-width: 35px;

			button {
				&:hover {
					transform: scale(1.2);
				}

				&:active {
					transform: scale(1.3);
					color: var(--bs-secondary);
				}
			}
		}
	}

	tr {
		&:first-of-type {
			border-top-color: transparent;
		}

		&:last-of-type {
			border-bottom-color: transparent;
		}
		
		& > th:first-of-type,
		& > td:first-of-type {
			border-left-color: transparent;
		}

		& > th:last-of-type,
		& > td:last-of-type {
			border-right-color: transparent;
		}

		& > th,
		& > td {
			&:nth-child(1),
			&:nth-child(6) {
				min-width: 90px;
				max-width: 90px;
			}

			&:nth-child(2),
			&:nth-child(7) {
				min-width: 300px;
			}
		}

		&.left{
			&:hover {
				td {
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						background-color: var(--bs-gray-400);
						cursor: pointer;
					}
				}
			}

			&.selected {
				td {
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						background-color: var(--bs-success);
						cursor: pointer;
					}
				}
			}
		}

		&.right {
			&:hover {
				td {
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8) {
						background-color: var(--bs-gray-400);
						cursor: pointer;
					}
				}
			}

			&.selected {
				td {
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8) {
						background-color: var(--bs-success);
						cursor: pointer;
					}
				}
			}

			.small-text {
				margin: 3px 0;
				font-size: 10px;
				line-height: 100%;
			}
		}

		&.reconciliated {
			td {
				background: rgba(var(--bs-primary-rgb), 0.3);
				position: relative;

				.reconciliations {
					display: flex;
					flex-direction: column;
					width: 100%;
					height: 100%;

					& > div {
						min-height: 50%;
						line-height: 120%;
						padding: 2px 5px;
						border-bottom: 1px solid var(--bs-gray-200);

						&:last-of-type {
							border-bottom: 1px solid transparent;
						}
					}

					.providerinvoice-counterparts {
						padding-left: 18px;
						font-size: 9px;
						line-height: 100%;

						div {
							&:first-of-type {
								margin-top: 3px;
							}

							&:not(:last-of-type) {
								margin-bottom: 5px;
							}
						}
					}

					.small-text {
						margin: 3px 0;
						font-size: 10px;
						line-height: 100%;
					}
				}
			}
		}

		&.partial {
			td {
				background-color: rgba(var(--bs-indigo-rgb), 0.4);

				&:nth-child(6),
				&:nth-child(7),
				&:nth-child(8) {
					cursor: pointer;

				}
			}

			&.partial_selected {
				td {
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8) {
						background-color: var(--bs-success);
						cursor: pointer;
					}
				}
			}
		}

		&.hidden {
			display: none;
		}
	}

	th,td {
		user-select: none;

		&:nth-child(1),
		&:nth-child(6) {
			max-width: 100px;
		}

		&:nth-child(2),
		&:nth-child(7) {
			max-width: 300px;
		}

		&:nth-child(3),
		&:nth-child(8) {
			max-width: 150px;
			text-align: right;
		}

		&:nth-child(4) {
			text-align: right;
		}

		&:nth-child(5) {
			/* width: 50px; */
			background: var(--bs-gray-200);
		}
	}
`;

const ButtonReconciliation = styled.button`
	position: fixed;
	display: flex;
	align-items: center;
	bottom: 20px;
	left: 50%;
	width: 250px;
	margin-left: -125px;
	z-index: 99;
	color: white;
	font-size: 16px;
	line-height: 18px;
	text-transform: uppercase;
	box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
`;

export default function BankReconciliationMovements() {
	let { id } = useParams();

	let [forceReload, setForceReload] = useState(null);
	let [treasury, setTreasury] = useState(undefined);
	let [movements, setMovements] = useState(undefined);
	let [reconciliationModalOpened, setReconciliationModalOpened] = useState(false);
	let [tradeCategoryModalOpened, setTradeCategoryModalOpened] = useState(false);

	let [selectedMovements, setSelectedMovements] = useState([]);
	let [dateFrom, _setDateFrom] = useState(moment().startOf('year').format('YYYY-MM-DD'));
	let setDateFrom = (val) => {
		_setDateFrom(val);
		setMovements(undefined);
	}
	let [dateTo, _setDateTo] = useState(moment().endOf('year').format('YYYY-MM-DD'));
	let setDateTo = (val) => {
		_setDateTo(val);
		setMovements(undefined);
	}
	let [showNoReconciliatedOnly, setShowNoReconciliatedOnly] = useState(false);
	let [expensesIncomesFilter, setExpensesincomesFilter] = useState('all');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [search, setSearch] = useState('');

	// Get movements
	useEffect(() => {
		const getData = async () => {
			let params = {
				date_from: dateFrom,
				date_to: dateTo
			};

			let result = await BankReconciliationService.getMovementsList(id, params);

			setMovements({...result});
			setSkeletonRows(result.length);
		}
		getData();
	}, [id, dateFrom, dateTo, forceReload]);

	// Get treasury
	useEffect(() => {
		const getData = async () => {
			let result = await TreasuriesService.get(id);
			if ( result ) {
		    	setTreasury({...result});
			}
		}
		getData();
	}, [id]);

	// Execute height fix
	useEffect(() => {
		setTimeout(() => {
			let elementsToFixHeight = document.querySelectorAll('[data-heightfixto]');
			elementsToFixHeight.forEach((el) => {
				let idx = el.dataset.heightfixto;

				// Find the FROM element
				let fromEl = document.querySelector('[data-heightfixfrom="' + idx + '"]');
				if ( !fromEl ) return;

				el.style.height = fromEl.offsetHeight + 'px';
			});
		}, 100);
	}, [movements]);

	const addYears = (qty) => {
		let newDateTo = moment(dateTo).add(qty, 'years').format('YYYY-MM-DD');
		let newDateFrom = moment(dateFrom).add(qty, 'years').format('YYYY-MM-DD');

		setDateFrom(newDateFrom);
		setDateTo(newDateTo);
	}

	const selectMovement = (movement) => {
		let newSelectedMovements = [...selectedMovements];
		let selectedIdx = newSelectedMovements.findIndex((el) => el.class === movement.class && el.id === movement.id);
		if ( selectedIdx !== -1 ) newSelectedMovements.splice(selectedIdx, 1);
		else newSelectedMovements.push(movement);
		setSelectedMovements(newSelectedMovements); 
	}

	const removeReconciliation = async (value) => {
		let c = window.confirm('¿Seguro que quieres desconciliar estos movimientos?');
		if ( !c ) return;

		let id = value.reconciliation[0].bank_reconciliation_id;

		let result = await BankReconciliationService.remove(id);
		if ( result === true ) {
			toast.warning('Movimientos desconciliados');
			
			// Get dates to reupdate
			let dates = [];
			dates.push(value.date);
			value.reconciliation?.forEach(el => {
				if ( el.entity?.date ) dates.push(el.entity.date);
			});

			// Reupdate
			refreshRowsFromDates(dates);
		}

		if ( result === false ) {
			toast.error('Ha ocurrido un error al guardar');
		}
	}

	const showReconciliationButton = (() => {
		let left = 0;
		let right = 0;

		selectedMovements.forEach((el) => {
			if ( el.class === 'App\\Models\\BankMovement' ) left += 1;
			else right += 1;
		});
			
		return (left === 1 && right === 0) || (left > 0 && right === 1);
	})();

	const openEntity = async (e, entity, otherData) => {
		e.stopPropagation();

		if ( entity.class.indexOf('BankRemittance') !== -1 ) {
			openPopupWindow(getRouterBasename() + '/bankremittances?popup=true&id=' + entity.id);
		}
		if ( entity.class.indexOf('ProviderInvoice') !== -1 ) openPopupWindow(getRouterBasename() + '/providers-invoices/edit/' + entity.id + '?popup=true');
		if ( entity.class.indexOf('Invoice') !== -1 ) openPopupWindow(getRouterBasename() + '/invoices/edit/' + entity.id + '?popup=true');
		if ( entity.class.indexOf('Expense') !== -1 ) openPopupWindow(getRouterBasename() + '/expenses/edit/' + entity.id + '?popup=true');
		if ( entity.class.indexOf('TradeCategory') !== -1 ) {
			setTradeCategoryModalOpened(otherData);
		}
		
	}

	const isHiddenBySearchRow = (el) => {
		return search.length > 0 && (
			el.title.toLowerCase().indexOf(search.toLowerCase()) === -1
			&&
			el.amount.toString().indexOf(search) === -1
			&&
			moment(el.date).format('DD-MM-YYYY').indexOf(search) === -1
		);
	}

	const isHiddenByReconciliated = (el) => {
		return showNoReconciliatedOnly && el.reconciliation;
	}

	const isHiddenByExpensesIncomesFilter = (el) => {
		if ( expensesIncomesFilter === 'expenses' ) return el.amount >= 0;
		if ( expensesIncomesFilter === 'incomes' ) return el.amount < 0;
		return false;
	}

	const refreshRowsFromDates = async (dates) => {
		if ( switchToPreventMultipleRefreshingAtSameTime ) return;

		switchToPreventMultipleRefreshingAtSameTime = true;

		// Remove duplicated dates
		dates = [...new Set(dates)];

		console.log('refreshrowFromDates', dates)

		// Loop dates and get movements from every date
		let newMovementsRows = {};
		for await ( let date of dates ) {
			let result = await BankReconciliationService.getMovementsList(id, {
				date_from: date,
				date_to: date
			});

			newMovementsRows[date] = result[date] ?? [];
		}
		
		// Mix with movements and update state
		let newMovements = {...movements};
		for ( let date in newMovementsRows ) {
			newMovements[date] = newMovementsRows[date];
		}
		setMovements(newMovements);

		switchToPreventMultipleRefreshingAtSameTime = false;
	}

	return (
		<LoggedLayout>
			<BusinessChecker />
			<section>
				<div className="page-title">
					<h1>
						Conciliación bancaria para&nbsp;
						<small><i>{ treasury?.name }</i></small>
					</h1>
					
					<NavLink to="/bank-reconciliation" className="btn btn-sm btn-light ms-auto">Volver</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">	
									<div className="input-group input-group-sm">
										<span className="input-group-text">
											Fechas
										</span>
										<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
										<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
									</div>
								</div>
								<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0">
									<div className="input-group">
										<button className="btn btn-sm btn-light" onClick={(e) => addYears(-1)}><i className="bi bi-arrow-left"></i></button>
										<button className="btn btn-sm btn-light" onClick={(e) => addYears(1)}><i className="bi bi-arrow-right"></i></button>
									</div>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<div className="d-inline-flex sbold me-5">Saldo real: { treasury?.balance } €</div>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" style={{minHeight: 'unset'}} placeholder="Buscar" onChange={(e) => setSearch(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select value={expensesIncomesFilter} onChange={(e) => setExpensesincomesFilter(e.target.value)} className="form-control form-control-sm">
										<option value="all">Mostrar gastos e ingresos</option>
										<option value="expenses">Sólo gastos</option>
										<option value="incomes">Sólo Ingresos</option>
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0 text-center">
									<div className="form-check form-switch mb-0 h-100 d-inline-flex align-items-center">
										<input className="form-check-input mb-1 me-2" type="checkbox" checked={showNoReconciliatedOnly} onChange={(e) => setShowNoReconciliatedOnly(e.target.checked)} />
										<label className="form-check-label">Mostrar no conciliados</label>
									</div>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded table-bordered">
									<thead>
										<tr>
											<th>Fecha</th>
											<th>Concepto</th>
											<th>Importe</th>
											<th>Saldo</th>
											<th></th>
											<th>Fecha</th>
											<th>Concepto</th>
											<th>Importe</th>
										</tr>
									</thead>
									<tbody>
										{ (movements && Object.keys(movements).length > 0) &&
											jsonToArray(movements).map((movementsRow, idx) => {
												return ( 
													<React.Fragment key={idx}>
														{jsonToArray(movementsRow.value).map((movement, movementIdx) => {
															let value = movement.value;
															let position = value.class === 'App\\Models\\BankMovement' ? 'left' : 'right';
															let selected = selectedMovements.findIndex((el) => el.class === value.class && el.id === value.id) !== -1;
															let partial = null;
															let partial_selected = false;
															let cannot_unlink = false;

															if ( movement.value.is_deletable === false ) cannot_unlink = true;

															if ( value.reconciliation ) {
																position = null;

																if ( value.reconciliation.length === 1 && value.reconciliation[0].entity.partial ) {
																	partial = true;
																	partial_selected = selectedMovements.findIndex((el) => el.class === value.reconciliation[0].entity.class && el.id === value.reconciliation[0].entity.id) !== -1;
																}

																for(let vrIdx in value.reconciliation) {
																	let vrItem = value.reconciliation[vrIdx];
																	if ( vrItem.entity.cannot_unlink ) {
																		cannot_unlink = true;
																		break;
																	}
																}
															}

															// ---- Check if hidden ------------------------------------------------------------------
																let isHidden = isHiddenBySearchRow({
																	title: value.concept,
																	amount: value.amount,
																	date: value.date
																});

																// Check on associated reconciliations
																if ( isHidden && value.reconciliation && value.reconciliation.length > 0 ) {
																	for(let i=0; i<value.reconciliation.length; i++) {
																		let vr = value.reconciliation[i];
																		isHidden = isHiddenBySearchRow({
																			title: vr.entity.concept,
																			amount: vr.entity.amount,
																			date: vr.entity.date
																		});
																		if ( !isHidden ) break;
																	}
																}

																// Check if is hidden by no reconciliated
																if ( !isHidden ) {
																	isHidden = isHiddenByReconciliated(value);
																}

																// Check if is hidden expensesIncomesFilter
																if ( !isHidden ) {
																	isHidden = isHiddenByExpensesIncomesFilter(value);
																}
															//  --------------------------------------------------------------------------------------

															return (
																<tr key={movementIdx} className={(position ? position : '') + ' ' + (selected ? 'selected' : '') + ' ' + (value.reconciliation ? 'reconciliated' : '') + ' ' + (partial ? 'partial' : '') + ' ' + (partial_selected ? 'partial_selected' : '') + ' ' + (isHidden ? 'hidden' : '')}>
																	{ (!value.reconciliation || value.reconciliation.length === 0) ? // Can select to reconciliate
																		<React.Fragment>
																			<td onClick={(e) => position === 'left' ? selectMovement(movement.value) : e.preventDefault()}>
																				{ position === 'left' &&
																					moment(value.date).format('DD-MM-YYYY')
																				}
																			</td>
																			<td onClick={(e) => position === 'left' ? selectMovement(movement.value) : e.preventDefault()}>
																				{ position === 'left' &&
																					value.concept
																				}
																			</td>
																			<td className={value.amount < 0 ? 'red' : 'green'} onClick={(e) => position === 'left' ? selectMovement(movement.value) : e.preventDefault()}>
																				{ position === 'left' &&
																					formatNumber(value.amount)
																				}
																			</td>
																		</React.Fragment>
																		: // Is reconciliated
																		<React.Fragment>
																			<td>{moment(value.date).format('DD-MM-YYYY')}</td>
																			<td>
																				{value.concept}
																			</td>
																			<td className={value.amount < 0 ? 'red' : 'green'}>{formatNumber(value.amount)}</td>
																		</React.Fragment>
																	}

																	<td className={'bankbalance ' + (value.balance < 0 ? 'red' : 'green')} onClick={(e) => position === 'left' ? selectMovement(movement.value) : e.preventDefault()}>
																		{value.balance !== undefined ? formatNumber(value.balance, 2) : ''}
																	</td>
																	
																	<td className="text-center">
																		{ (value.reconciliation && value.reconciliation.length > 0 && !cannot_unlink) &&
																			<button className="btn-unstyled text-white" onClick={() => removeReconciliation(value)}><i className="bi bi-arrow-left-right"></i></button>
																		}
																		{ cannot_unlink &&
																			<button className="btn-unstyled text-white pe-none" style={{cursor: 'pointer'}}><i className="bi bi-lock"></i></button>
																		}
																		{ (selected && showReconciliationButton) &&
																			<button className="btn-unstyled" onClick={() => setReconciliationModalOpened(true)}><i className="bi bi-arrow-up-circle-fill"></i></button>
																		}
																	</td>

																	{ (!value.reconciliation || value.reconciliation.length === 0) ? // Can select to reconciliate
																		<React.Fragment>
																			<td onClick={(e) => position === 'right' ? selectMovement(value) : e.preventDefault()}>
																				{ position === 'right' &&
																					moment(value.date).format('DD-MM-YYYY')
																				}
																			</td>
																			<td onClick={(e) => position === 'right' ? selectMovement(value) : e.preventDefault()}>
																				{ position === 'right' &&
																					<React.Fragment>
																						<span onClick={(e) => openEntity(e, value)} className={'badge ' + value.concept_color}>{value.concept_type}</span>
																						&nbsp;
																						{/*(value.class.indexOf('ProviderInvoice') !== -1 && !value.accounted) &&
																							<span className="badge badge-rounded bg-danger me-2">&nbsp;</span>
																						*/}
																						{value.concept}

																						{value.class.indexOf('ProviderInvoice') !== -1 &&
																							<div className="small-text">{value.category_breadcrumbs}</div>
																						}
																					</React.Fragment>
																				}
																			</td>
																			<td className={value.amount < 0 ? 'red' : 'green'} onClick={(e) => position === 'right' ? selectMovement(movementsRow.key, movement.key) : e.preventDefault()}>
																				{ position === 'right' &&
																					formatNumber(value.amount)
																				}
																			</td>
																		</React.Fragment>
																		: // Is reconciliated
																		<React.Fragment>
																			<td onClick={(e) => partial ? selectMovement(value.reconciliation[0].entity) : e.preventDefault()}>{moment(value.reconciliation[0].entity.date).format('DD-MM-YYYY')}</td>
																			<td onClick={(e) => partial ? selectMovement(value.reconciliation[0].entity) : e.preventDefault()}>
																				<div className="reconciliations">
																					{ value.reconciliation.map((reconciliation, reconciliationIdx) => {
																						return (
																							<div 
																								key={reconciliationIdx}
																								onClick={(e) => partial ? selectMovement(reconciliation.entity) : e.preventDefault()}
																								data-heightfixfrom={value.id + '|' + reconciliationIdx}
																							>
																								<span className={'badge ' + reconciliation.entity.concept_color} onClick={(e) => openEntity(e, reconciliation.entity, value)}>{reconciliation.entity.concept_type}</span>&nbsp;
																								{(reconciliation.entity.class.indexOf('ProviderInvoice') !== -1 && !reconciliation.entity.accounted) &&
																									<span className="badge badge-rounded bg-danger me-2">&nbsp;</span>
																								}
																								{reconciliation.entity.concept ?? '-'}

																								{ 
																									(
																										reconciliation.entity.class.indexOf('TradeCategory') !== -1
																										&&
																										reconciliation.entity.text
																									) &&
																									<div className="small-text">{reconciliation.entity.text}</div>
																								}
																							</div>
																						)
																					})}
																				</div>
																			</td>
																			<td onClick={(e) => partial ? selectMovement(value.reconciliation[0].entity) : e.preventDefault()}>
																				<div className="reconciliations">
																					{ value.reconciliation.map((reconciliation, reconciliationIdx) => {
																						return (
																							<div 
																								key={reconciliationIdx} 
																								onClick={(e) => partial ? selectMovement(reconciliation.entity) : e.preventDefault()}
																								className={value.amount < 0 ? 'red' : 'green'}
																								data-heightfixto={value.id + '|' + reconciliationIdx}
																							>
																								{formatNumber(reconciliation.entity.amount)}
																							</div>
																						)
																					})}
																				</div>
																			</td>
																		</React.Fragment>
																	}
																</tr>
															);
														})}
													</React.Fragment>
												);
											})
										}

										{ (movements && !Object.keys(movements).length) && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ movements === undefined && <TrSkeleton rows={skeletonRows} columns={8} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								
							</div>
						</div>
					</div>
				</div>
				{ showReconciliationButton && 
					<ButtonReconciliation className="btn btn-primary" onClick={() => setReconciliationModalOpened(true)}>
						<i className="bi bi-arrow-left-right"></i>
						Conciliar movimientos seleccionados
					</ButtonReconciliation>
				}
			</section>

			{ reconciliationModalOpened && 
				<ReconciliationModal 
					movements={selectedMovements}
					closeCallback={(unselectSelectedMovements) => {
						if ( unselectSelectedMovements ) setSelectedMovements([]);

						let dates = selectedMovements.map(item => item.date);
						refreshRowsFromDates(dates);

						setReconciliationModalOpened(false);
					}}
				/>
			}

			{ tradeCategoryModalOpened && 
				<TradeCategoryModal 
					data={tradeCategoryModalOpened}
					closeCallback={() => {
						let dates = [tradeCategoryModalOpened.date];
						refreshRowsFromDates(dates);

						setTradeCategoryModalOpened(false);
					}}
				/>
			}
		</LoggedLayout>
	);
}