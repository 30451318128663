import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import moment from 'moment/min/moment-with-locales';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import CustomInput from 'components/CustomInput';
import EmpoweredSelector from "components/EmpoweredSelector";
import CustomCheckbox from "components/CustomCheckbox";
import { default as TradeCategoriesService } from 'services/TradeCategories';
import CustomSelect from "components/CustomSelect";

const ModalStyled = styled.div`
    .modal {
	    background: rgba(0, 0, 0, 0.4);

        .modal-body {

            #recurrence-period-wrapper {
                color: var(--bs-primary);
                font-size: 10px;
                display: flex;
                align-items: center;

                input[type=number] {
                    width: 20px;
                    margin: 0 5px;
                    display: inline-block;
                }

                select {
                    display: inline-block;
                    width: 120px;
                    margin: 0 5px;
                }
            }

        }
    }
`;

const FamilyRowModal = (props) => {
	const modalRef = useRef(null);

    const data = props.data;
    const closeCallback = props.closeCallback;

    const [newData, setNewData] = useState({...data, category_type: data?.category_type ?? 'incomes'});

    const [isRecurrent, setIsRecurrent] = useState(false);
    const [period, _setPeriod] = useState(1);
    const setPeriod = (period) => {
        if ( period < 1 ) period = 1;
        if ( period > 6 ) period = 6;
        _setPeriod(period);
    }
    const [monthStart, setMonthStart] = useState(1);
    const [amount, setAmount] = useState(false);

    const setDataField = (field, value) => {
        const updatedData = {...newData};
        updatedData[field] = value;
        setNewData(updatedData);
    }

    useEffect(() => {
        const modal = new Modal(modalRef.current, {
			backdrop: false,
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';

            closeCallback();
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

        modal.show();
    }, [closeCallback]);

    const closeModal = (dataToSave = null) => {
        if ( closeCallback ) {
            // If recently created row and no changes, then remove when exit
            if ( dataToSave?.recentlyCreated && JSON.stringify(data) === JSON.stringify(dataToSave) ) { 
                closeCallback(null);
            } else { // Save data
                if ( dataToSave ) {
                    // If recurrent calc months
                    if ( isRecurrent ) {
                        let months = {};
                        for(let i = 1; i <= 12; i++) {
                            months[i] = {
                                amount: null
                            };
                        }

                        let counterToSet = 0;
                        for (let i = monthStart; i <= 12; i++) {
                            if ( i === monthStart || counterToSet === 0 ) {
                                months[i].amount = amount;
                                counterToSet = period;
                            }
                            counterToSet--;
                        }

                        dataToSave.monthsAmount = months;
                    }

                    // Delete recently to prevent show "add fields" again
                    delete dataToSave?.recentlyCreated;
                }

                const modal = Modal.getInstance(modalRef.current);
		        modal.hide();

                closeCallback(dataToSave);
            }
        }
    }

    const backdropCloseModal = (e) => {
        if ( e.target === modalRef.current ) closeModal(data);
    }

    const deleteData = () => {
        if ( !window.confirm('¿Estás seguro de que deseas eliminar esta previsión?') ) return;

        closeModal(null);
    }

    const loadCategories = async (input, callback, args) => {
		let categories = await TradeCategoriesService.list(args.type, {
			search: input,
			no_paginate: true,
            with_breadcrumbs: true
		});

		let formatted = categories?.map(el => {
            let label = null;
            if ( el.level > 1 ) {
                let spaces = [];
                for (let i = 0; i < el.level - 1; i++) {
                    spaces.push(<React.Fragment key={i}>&nbsp;&nbsp;&nbsp;</React.Fragment>);
                }
                label = <>
                    {spaces} 
                    <i className="bi bi-arrow-return-right"></i> 
                    <span className="fw-bold text-dark me-1">{el.code}</span>
                    {el.name}
                </>;
            } else {
                label = <>
                    <span className="fw-bold text-dark me-1">{el.code}</span>
                    {el.name}
                </>
            }

			return {
				value: el, 
				label: label
			};
		});
		callback(formatted);
	}

    const setCategoryType = (type) => {
        let updatedData = {...newData};
        updatedData.category_type = type;
        updatedData.category = null;
        setNewData(updatedData);
    }

    const canSave = newData?.name?.length > 0 && newData?.name?.length < 100 && newData?.category?.id > 0;

    return (
        <ModalStyled>
            <div className="modal" tabIndex="-1" ref={modalRef} onMouseDown={(e) => backdropCloseModal(e)}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{newData.recentlyCreated ? 'Añadir' : 'Editar'} previsión</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal(data)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-0">
                                        <CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={newData?.name ?? ''} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="mb-0">
                                        <CustomSelect label="Tipo" className="form-control form-control-sm" onChange={(e) => setCategoryType(e.target.value)} value={newData?.category_type ?? ''}>
                                            <option value="incomes">Ingresos</option>
                                            <option value="expenses">Gastos</option>
                                        </CustomSelect>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-0">
                                        <EmpoweredSelector
                                            args={{type: newData?.category_type}}
                                            placeholder="Categoría"
                							showPlaceholderHelper={true}
                                            load={(input, callback, args) => loadCategories(input, callback, args)}
                                            onChange={(value) => setDataField('category', value ? {id: value.id, name: value.breadcrumbs} : null)}
                                            timeout={250}
                                            value={newData?.category?.id ?? null}
                                            label={newData?.category?.name ?? ''}
                                            disabled={!newData?.category_type}
                                        />
                                    </div>
                                </div>
                                { newData.recentlyCreated &&
                                    <>
                                        <div className="col-md-4">
                                            <div className="mt-3">
                                                <CustomCheckbox 
                                                    label={'Importe recurrente'}
                                                    size={'md'}
                                                    checked={isRecurrent}
                                                    onChange={(e) => setIsRecurrent(e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                        { isRecurrent &&
                                            <>
                                                <div className="col-md-5" id="recurrence-period-wrapper">
                                                    <div className="mt-3">
                                                        Cada 
                                                        <input 
                                                            type="number" 
                                                            className="form-control form-control-sm no-arrows" 
                                                            defaultValue={period ?? ''} 
                                                            onFocus={(e) => {
                                                                e.target.select();
                                                            }}
                                                            onKeyDown={(e) => {
                                                                e.target.value = '';
                                                            }}
                                                            onChange={(e) => {
                                                                e.preventDefault();

                                                                let value = parseInt(e.target.value);
                                                                if ( !isNaN(value) ) {
                                                                    if ( value < 1 ) value = 1;
                                                                    if ( value > 6 ) value = 6;
                                                                    e.target.value = value;
                                                                    setPeriod(value);
                                                                } else {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        /> 
                                                        meses empezando en
                                                        <select className="form-control form-control-sm" value={monthStart} onChange={(e) => setMonthStart(e.target.value)}>
                                                            {Array.from({length: 12}, (v, k) => k + 1).map((month) => {
                                                                return <option key={month} value={month}>{moment('2024-' + (month < 10 ? '0' : '') + month + '-01').locale('es').format('MMMM')}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="mt-3">
                                                        <CustomInput label="Importe" type="number" className="form-control form-control-sm" onChange={(e) => setAmount(e.target.value)} value={amount ?? ''} />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                }                                
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                            { !newData.recentlyCreated &&
                                <button type="button" className="btn btn-sm btn-link text-danger" onClick={deleteData}>Eliminar</button>
                            }
                            <button type="button" className="btn btn-sm btn-primary text-white ms-auto" onClick={() => closeModal(newData)} disabled={!canSave}>Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalStyled>
    );
}

export default FamilyRowModal;